<template>
	<div class="login" v-loading="loading">
		<div class="left">
			<img src="../assets/img/login/logins.png" alt="" class="logos">
			<div class="banners">
				<el-carousel>
					<el-carousel-item v-for="item in 4" :key="item" :loop="true">
						<div class="banners_img">
							<img src="../assets/img/login/bl_l.png" alt="">
						</div>
					</el-carousel-item>
				</el-carousel>
			</div>
		</div>
		<div class="right">
			<div class="right_top">
				考勤综合管理系统
			</div>
			<div class="right_login">
				<div class="right_input">
					<img src="../assets/img/login/user.png" alt="">
					<input type="text" class="inpt_user" v-model="mobile" name="" id="" placeholder="账号">
				</div>
				<div class="right_input">
					<img src="../assets/img/login/passsed.png" alt="">
					<input type="password" class="inpt_password" v-model="password" name="" id="" placeholder="密码">
				</div>
			</div>
			<div class="btns">
				<button class="btns1" @click="login_submit()">登录</button>
			</div>
			<div class="banquan">
				<a href="https://beian.miit.gov.cn/" target="_blank">
					Copyright © 2024 嵩县金牛有限责任公司
				</a>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		name: "login",
		components: {},
		data() {
			return {
				loading: false,
				loading_btn: false,
				btn_text: '',
				mobile: "15231207780",
				password: "123456",
				code: '',

				isSend: true, //可以发送
				timeFun: null,
			};
		},
		computed: {},
		watch: {


		},
		created() {
			// localStorage.setItem('token','')
			localStorage.clear()
			this.$store.dispatch('get_type_setting')

		},
		beforeDestroy() {
			clearInterval(this.timeFun);
		},
		methods: {
			login_submit() {

				if (!this.mobile.trim()) {
					alertErr('请输入账号');
					return;
				}


				if (!this.password.trim()) {
					alertErr('请输入密码');
					return;
				}
				this.loading = true
				this.$api("admin_login", {
					username: this.mobile.trim(),
					password: this.password.trim(),
				}, "post").then((res) => {
					console.log("登录", res);
					if (res.code == 200) {
						alertSucc('登录成功')
						// this.loading = false
						localStorage.setItem('token', res.data.token)
						localStorage.setItem('logo_data', JSON.stringify(res.data))
						this.get_user()
						

						// this.$store.dispatch('query_user')
						// this.$router.push('/index')
					} else {
						this.loading = false
						alertErr(res.msg)
					}

				});
			},
			//获取用户信息
			get_user(){
				// this.loading = true
				this.$api("getAdminInfo", {
					 
				}, "get").then((res) => {
					console.log("获取用户信息", res);
					if (res.code == 200) {
						this.loading = false
						localStorage.setItem('cache_user', JSON.stringify(res.data))
						this.get_login_qx(res.data)

						// this.$store.dispatch('query_user')
						// this.$router.push('/index')
					} else {
						this.loading = false
						alertErr(res.msg)
					}

				});
			},
			//判断用户权限
			get_login_qx(data){
				this.$api("getAdminRole", {
					id:data.role_id
				}, "get").then((res) => {
					this.loading = false
					console.log('菜单列表',res)
					if (res.code == 200) {
						let qx_list=res.data.roles.split(',')
						if(qx_list.find(v => v == 2)){
							this.$router.push('/Employee_Information')
						}else if(qx_list.find(v => v == 2)){
							this.$router.push('/Social_security_management')

						}else if(qx_list.find(v => v == 3)){
							this.$router.push('/Employee_Information')

						}else if(qx_list.find(v => v == 4)){
							this.$router.push('/Attendance_settings/Attendance_rules')

						}else if(qx_list.find(v => v == 5)){
							this.$router.push('/Gift_management')

						}else if(qx_list.find(v => v == 6)){
							this.$router.push('/Labor_Management')

						}else if(qx_list.find(v => v == 7)){
							this.$router.push('/Real_time_reports')

						}else if(qx_list.find(v => v == 8)){
							this.$router.push('/Account_management')

						}

					} else {
						alertErr(res.msg)
					}
				});
			},


		},
	};
</script>
<style scoped lang="less">
	.login {
		width: 100vw;
		height: 100vh;
		display: flex;
		justify-content: space-between;

		.left {
			width: 50%;
			// height: 1080px;
			height: 100%;
			// background: url('../assets/img/login/bl_l.png');//
			// position: fixed;
			text-align: center;
			position: relative;

			// padding-top: 243px;
			.logos {
				width: 362px;
				height: 71px;
				position: absolute;
				left: 36px;
				top: 36px;
				z-index: 2;
			}

			.banners {
				position: relative;
				z-index: 1;
				width: 100%;
				height: 100%;

				// background-color: red;
				/deep/.el-carousel {
					height: 100%;

					.el-carousel__container {
						height: 100%;

						.banners_img {
							width: 100%;
							height: 100%;

						}
					}

					.el-carousel__indicators {
						.el-carousel__indicator {
							.el-carousel__button {

								width: 14px;
								height: 14px;
								background: #FFFFFF;
								border-radius: 100px 100px 100px 100px;
								opacity: 0.5;
							}

						}

						.is-active {
							.el-carousel__button {

								width: 48px;
								height: 14px;
								background: #FFFFFF;
								border-radius: 100px 100px 100px 100px;
								opacity: 1;

							}
						}
					}
				}


			}
		}

		.right {
			width: 50%;
			height: 100vh;
			position: relative;

			// height: 1080px;
			// background: url('../assets/img/login/bg_r1.png');
			.right_top {
				text-align: center;
				padding-top: 217px;
				font-family: Microsoft YaHei, Microsoft YaHei;
				font-weight: bold;
				font-size: 36px;
				color: #333333;
			}

			.right_login {
				padding-top: 110px;
				width: 424px;
				margin: auto;

				.right_input {
					width: 424px;
					height: 50px;
					background: #F7F8FA;
					border-radius: 4px 4px 4px 4px;
					opacity: 1;
					display: flex;
					align-items: center;
					padding-left: 16px;
					padding-right: 16px;
					margin: auto;
					margin-bottom: 22px;

					.inpt_user {
						padding-left: 20px;
					}

					.inpt_password {
						padding-left: 20px;
					}

					input {
						width: 100%;
					}
				}

				.right_input_code {
					display: flex;
					align-items: center;
					justify-content: space-between;
					width: 424px;
					height: 50px;
					margin-bottom: 22px;

					.inputs {
						width: 293px;
						height: 50px;
						background: #F7F8FA;
						border-radius: 4px 4px 4px 4px;
						display: flex;
						align-items: center;
						padding-left: 16px;
						padding-right: 16px;

						.inpt_user {
							padding-left: 20px;
						}

						.inpt_password {
							padding-left: 20px;
						}

						input {
							width: 100%;
						}
					}

					.code_btns {
						width: 121px;
						height: 50px;
						background: #273A8E;
						border-radius: 4px 4px 4px 4px;
						line-height: 50px;
						text-align: center;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						font-size: 14px;
						color: #FFFFFF;
						cursor: pointer;
					}
				}

			}

			.forgot_password {
				width: 424px;
				margin: auto;
				// padding-top: 23px;
				display: flex;
				align-items: center;
				justify-content: end;

				.text {
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					font-size: 14px;
					color: #686868;
					cursor: pointer;
				}
			}

			.btns {
				margin-top: 60px;
				text-align: center;

				.btns1 {
					width: 424px;
					height: 50px;
					background: #2373C8;
					border-radius: 27px 27px 27px 27px;
					text-align: center;
					line-height: 50px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					font-size: 16px;
					color: #FFFFFF;

				}
			}

			.banquan {
				position: absolute;
				bottom: 40px;
				width: 100%;
				text-align: center;
				font-family: Microsoft YaHei, Microsoft YaHei;
				font-weight: 400;
				font-size: 14px;
				color: #7E7E7E;

				a {
					text-align: center;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					font-size: 14px;
					color: #7E7E7E;
				}
			}

		}
	}
</style>